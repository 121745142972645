<template>
   <div id='quillEditorQiniu'>
       <!-- 基于elementUi的上传组件 el-upload begin-->
       <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :accept="'image/*'"
            :data="qiniuForm"
            :show-file-list="false"
            :on-success="uploadEditorSuccess"
            :on-error="uploadEditorError"
            :before-upload="beforeEditorUpload">
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor  class="editor"  v-model="content" ref="customQuillEditor" :options="editorOption" >
        </quill-editor>
   </div>
</template>
 
<script>
// import { quillEditor } from 'vue-quill-editor'
// import { uploadImage } from "@/api/meeting";
 
//自定义编辑器的工作条
const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
 
        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction
 
        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
 
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],
        ['link', 'image', 'video'],
        ['clean']                                         // remove formatting button
    ];
export default {
    data(){
        return {
           quillUpdateImg:false,
           content:'',
           editorOption:{
                  placeholder:'你想说什么？',
                  modules: {
                        toolbar: {
                            container: toolbarOptions,  // 工具栏
                            handlers: {
                                'image': function (value) {
                                    if (value) {
                                        document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
                        }
                 }
            },
            qiniuForm:{
                'key': '',
                'token': '',
                'domain':''
            }
        }
    },
    props:{
        token:String, //七牛云上传的token,类型为String
        oldContent:String, //七牛云上传的内容,类型为String
        domain:String, //七牛云上传的域地址,类型为String
        uploadUrl:String  //从七牛云上拿到自己的上传地址,类型为String
    },
    methods:{
        //上传图片之前async 
        beforeEditorUpload(res, file){
            //显示上传动画
            this.quillUpdateImg = true;
            //  const res1 = await uploadImage()
            // console.log(res1,'=====');
            // this.$emit('before',res, file)
            console.log(this.qiniuForm,'====');
        },
 
        // 上传图片成功
         uploadEditorSuccess(res, file) {
          // this.$emit('upload',res, file)
          console.log(res, file);
          console.log(this.qiniuForm,'====22222');
            //拼接出上传的图片在服务器的完整地址
            let imgUrl = this.qiniuForm.domain+ res.key;
 
            //重置上传文件key，为下次上传做好准备
            this.qiniuForm.key =  new Date().getTime()+""+Math.floor(Math.random()*1000);
 
            // 获取富文本组件实例
            let quill = this.$refs.customQuillEditor.quill;
 
           // 获取光标所在位置
            let length = quill.getSelection().index;
 
            // 插入图片  res.info为服务器返回的图片地址
            quill.insertEmbed(length, 'image', imgUrl)
 
            // 调整光标到最后
            quill.setSelection(length + 1)
 
            //取消上传动画
            this.quillUpdateImg = false;
 
        },
        
        // 上传图片失败
        uploadEditorError(res, file) {
          console.log(this.qiniuForm,'====44444');
            //页面提示
            this.$message.error('上传图片失败')
 
            //取消上传动画
            this.quillUpdateImg = false;
        },
 
        
 
       
    },
    created () {
      if(this.oldContent){
        this.content = this.oldContent
      }
    },
    mounted () {
      setTimeout(() => {
        console.log(this.qiniuForm,'mounted');
        this.qiniuForm.key =  new Date().getTime()+""+Math.floor(Math.random()*1000);
        this.qiniuForm.token = this.token;
        this.qiniuForm.domain = this.domain;
      }, 500);
    },
    watch:{
        content(newVal, oldVal) {
          this.$emit('input', newVal);
        }
    }
 
    
 
}
</script>
 
<style>
.editor {
  line-height: normal !important;
  height: 400px;
  margin-bottom: 50px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>