<template>
  <div class="wrap">
    <div>请填写相关信息</div>
    <div class="mt20">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="分类id：">
          <el-select v-model="form.cate_id" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章标题：">
          <el-input v-model="form.title" style="width: 300px"></el-input>
        </el-form-item>
         <el-form-item label="图文详情(仅用于培训资料)：">
           <template></template>
          <el-upload
            v-model="form.img"
            :value='form.img'
            :action="uploadCalendarUrl"
            list-type="picture-card"
            :limit="1"
            :with-credentials="false"
            :file-list="photoList"
            :before-upload="handleCalendarPreview"
            :on-success="handleCalendarSuccess"
            :data="qiniuCalendarData"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="imgCalendarDialogVisible">
            <img
              width="100%"
              v-if="dialogCalendarImageUrl"
              :src="dialogCalendarImageUrl"
              alt=""
            />
          </el-dialog>
        </el-form-item>
        <el-form-item label="文章内容：">
          <!-- 回显 -->
          <quill-editor-con
            :token="qiniuForm.token"
            :oldContent="form.content"
            v-model="form.content"
            :domain="qiniuForm.domain"
            :uploadUrl="uploadImgUrl"
            class="editor"
          ></quill-editor-con>
          <!-- <quill-editor v-model="form.content" class="editor" :value="form.content"></quill-editor> -->
        </el-form-item>
      </el-form>
      <div class="ml140 mt70">
        <el-button type="info" @click="cancel">取消</el-button>
        <el-button type="success" v-if="type == 0" @click="handleAdd"
          >立即添加</el-button
        >
        <el-button type="success" v-else @click="Update">更新</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import QuillEditorCon from '../../components/QuillEditorCon'
import { materialCreate, materialSave, materialUpdate } from '@/api/zltype.js'
import { uploadImage } from '@/api/meeting'
// import QuillEditorCon from '../../components/QuillEditorCon.vue'
export default {
  components: {
    QuillEditorCon
    // QuillEditorCon
  },
  data () {
    return {
      form: {
        content: '',
        img:''
      },
      options: [],
      dialogVisible: false,
      uploadImgUrl: 'https://up-z2.qiniup.com', // 必选参数，上传的地址https://up-z2.qiniup.com
      qiniuData: {
        key: '',
        token: '',
        domain: ''
      },
      uploadCalendarUrl: "https://up-z2.qiniup.com",
      qiniuCalendarData: {
        key: "",
        token: "",
      },
      imgCalendarDialogVisible: false,
      dialogCalendarImageUrl: "",
      qiniuForm: {
        key: '',
        token: '',
        domain: ''
      },
      imgDialogVisible: false,
      dialogImageUrl: null,
      qiniuImgDomain: null,
      type: 0,
      content:'qwe123',
      photoList:[]
    }
  },
  created () {
    this.getHtml()
    this.type = this.$route.query.type
    console.log(this.type);
    if (this.type == 1) {
      // 编辑
      this.options = this.$route.query.cate
      this.form = this.$route.query.info
        let url1 = {}
        url1.url = this.form.img
        this.photoList[0] = url1
      console.log(this.form);
      console.log(this.photoList,'---');
      // this.photoList[0].url = this.form.img
    } else {
      this.getList()
    }
  },
  methods: {
    // 获取上传地址
    async getHtml () {
      const res = await uploadImage()
      if (res.data.code == 1) {
        this.qiniuForm.domain = res.data.result.domain
        this.qiniuForm.token = res.data.result.token
        this.qiniuForm.key = res.data.result.oss_file_name
        console.log(this.qiniuForm,'create');
      }
    },
    // 获取列表
    async getList () {
      const res = await materialCreate({ page: 1 })
      this.options = res.data.result
    },
    cancel () {
      this.$router.push('./training-ziliao')
    },
    async handleAdd () {
      const res = await materialSave(this.form)
      if (res.data.code == 1) {
        this.$message.success('添加成功')
        setTimeout(() => {
          this.$router.push('./training-ziliao')
        }, 1000)
      }
    },
    // 更新
    async Update () {
      const res = await materialUpdate(this.form)
      if (res.data.code == 1) {
        this.$message.success('添加成功')
        setTimeout(() => {
          this.$router.push('./training-ziliao')
          this.type = 0
        }, 1000)
      }
    },
    // 图文详情 大小不做限制
    async handleCalendarPreview(file) {
        const res = await uploadImage();
        if (res.data.code === 1) {
        this.qiniuCalendarData.key = res.data.result.oss_file_name;
        this.qiniuCalendarData.token = res.data.result.token;
        this.qiniuCalendarDomain = res.data.result.domain;
        }
    },
    handleCalendarSuccess(res) {
      this.dialogCalendarImageUrl = this.qiniuCalendarDomain + `${res.key}`;
      console.log(this.dialogCalendarImageUrl,'成功');
      this.form.img = this.dialogCalendarImageUrl;
      // console.log(this.form.content);
    },

  }
}
</script>

<style lang="less" scoped>
.wrap {
  padding: 20px;
  color: #666;
}
.mt20 {
  margin-top: 20px;
}
.mt70 {
  margin-top: 70px;
}
.ml140 {
  margin-left: 100px;
}
</style>
